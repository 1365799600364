import { Box, Link } from "@mui/material";
import { FC } from "react";

import logo from "../../assets/images/logo_m_white.png";

export interface UnderConstructionPageProps {}

export const UnderConstructionPage: FC<UnderConstructionPageProps> = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box />
      <img src={logo} alt="logo" height={150} style={{ aspectRatio: "auto" }} />
      <Link
        variant="body1"
        href="mailto:info@letterm.be"
        sx={{
          color: "#fff",
          textDecoration: "none",
          fontFamily: "Gotham Thin, sans-serif",
          textAlign: "center",
          marginBottom: 10,
          "&:hover": { textDecoration: "none" },
        }}
      >
        info@letterm.be
      </Link>
    </Box>
  );
};
