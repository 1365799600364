import { createBrowserRouter } from "react-router-dom";
import GvsInvoice from "../screens/GvsInvoice/GvsInvoice";
import { UnderConstructionPage } from "../screens/Home/UnderConstruction";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <UnderConstructionPage />,
  },
  // {
  //   path: "/gvs",
  //   element: <GvsInvoice />,
  // },
]);
